import { Address, idName, Phone } from './patient-registration.model';

export interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  id: number;
  canManageUsers: 1 | 0;
  dea: string;
  degree: string;
  googleCalendarKey: string;
  middleName: string;
  npi: string;
}

export class TokenExp {
  constructor(private _token: string, private _tokenExpDate: Date) {}

  get token() {
    if (!this._tokenExpDate || new Date() > this._tokenExpDate) {
      return null;
    }
    return this._token;
  }
}

export interface userInfo {
  dea: string;
  degree: string;
  email: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  middleName: string;
  npi: string;
}

export interface ProviderDetails {
  address: Address;
  dea: string;
  degree: string;
  email: string;
  name: string;
  npi: string;
  phone: string;
}

export interface UsersList {
  associatedUsers: idName[];
  authorizeToWriteMedOrder: 1 | 0;
  createdBy: string;
  dateEntered: string;
  dea: string;
  deaExpiryDate: string;
  degree: string;
  disabled: 0 | 1;
  dob: string;
  electronicSignatureCode: string;
  email: string;
  fax: string;
  firstName: string;
  fullName: string;
  gender: 'MALE' | 'FEMALE';
  id: number;
  initial: string;
  lastName: string;
  middleName: string;
  phone: Phone;
  ssn: number;
  googleCalendarKey?: string;
  stateLiceses: { state: State }[];
  personClasses?: { personClass: idName }[];
  associatedClinics?: idName[];
  suffix: string;
  type: string;
}

export interface EditeUser {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  initial: string;
  gender: 'MALE' | 'FEMALE';
  dob: string;
  ssn?: number;
  phone?: Phone;
  email?: string;
  fax?: string;
  electronicSignatureCode: string;
  authorizeToWriteMedOrder: 0 | 1;
  degree?: string;
  dea?: string;
  deaExpiryDate?: string;
  type?: string;
  googleCalendarKey?: string;
  stateLicenses?: StateLicenses;
  personClasses?: { classId: number }[];
  associatedUsers?: { userId: number }[];
  associatedClinics?: { clinicId: number }[];
}

export interface AddUserReqBody extends EditeUser {
  accessCode: string;
  verifyCode: string;
}

export interface ChangeUserVerifyCodeReqBody {
  userId: number;
  newVerifyCode: string;
  confirmNewVerifyCode: string;
}

export interface ChangeUserAccessCodeReqBody {
  userId: number;
  newAccessCode: string;
  confirmNewAccessCode: string;
  newVerifyCode: string;
  confirmNewVerifyCode: string;
}

export interface StateLicenses {
  stateId;
  licenseNumebr;
  expiryDate;
}

export interface State {
  expiryDate: string;
  id: number;
  licenseNumber: string;
  name: string;
}
