import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule,
} from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

import { LayoutComponent } from './layout/layout.component';
import { AuthInterCeptor } from './services/authInterceptor.service';

@NgModule({
  declarations: [LayoutComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    ToastNoAnimationModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
    }),
    NgxUiLoaderModule, // import NgxUiLoaderModule
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      excludeRegexp: ['https://api.romtix.com/api/quickSearchPatient$'],
    }),
  ],
  exports: [LayoutComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterCeptor,
      multi: true,
    },
    CookieService,
  ],
})
export class CoreModule {}
