import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { TokenExp, User } from '../../shared/_models/User.modal';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  public user = new BehaviorSubject<User>(null);
  public isUser = new BehaviorSubject<TokenExp>(null);
  private api = 'https://api.romtix.com/api/';
  private timer: any = null;
  private hour = 3600000;

  constructor(
    private http: HttpClient,
    private router: Router,
    private modalService: NgbModal
  ) {}

  login(userName: string, password: string) {
    return this.http
      .post<any>(`${this.api}patientLogin`, {
        userName: userName,
        password: password,
      })
      .pipe(
        catchError((err) => throwError(err)),
        tap((resData) => {
          localStorage.clear();
          this.handleAuthentication(resData.token);
        })
      );
  }

  logOut() {
    localStorage.clear();
    this.isUser.next(null);
    this.user.next(null);
    this.modalService.dismissAll('LogOut');
    this.router.navigate(['/auth'], { replaceUrl: true });
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = null;
  }

  changePassword(
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ) {
    return this.http.post(`${this.api}changePassword`, {
      currentPassword,
      newPassword,
      confirmNewPassword,
    });
  }

  autoLogin(token?: string) {
    if (token) {
      this.refreshToken(token);
      window.location.href = window.location.href.split('?token=')[0];
    }
    const expInfo: {
      _token: string;
      _tokenExpDate: string;
    } = JSON.parse(localStorage.getItem('PatientToken'));
    if (!expInfo) {
      return;
    }
    const newExpToken = new TokenExp(
      expInfo._token,
      new Date(expInfo._tokenExpDate)
    );
    if (newExpToken.token) {
      this.isUser.next(newExpToken);
      const expirationDuration =
        new Date(expInfo._tokenExpDate).getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
    }
  }

  autoLogout(duration) {
    this.timer = setTimeout(() => {
      this.logOut();
    }, duration);
  }

  handleAuthentication(token: string) {
    this.refreshToken(token);
  }

  refreshToken(token: string) {
    const expToken = new TokenExp(
      token,
      new Date(new Date().getTime() + this.hour)
    );
    this.isUser.next(expToken);
    localStorage.setItem('PatientToken', JSON.stringify(expToken));
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = null;
    this.autoLogout(this.hour);
  }
}
