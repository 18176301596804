import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserDataService } from '../services/user-data.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public loading = false;
  public unsub: Subscription;

  constructor(private userDataService: UserDataService) {}

  ngOnInit(): void {
    let token = window.location.href.split('?token=')[1];
    this.userDataService.autoLogin(token);
  }

  ngOnDestroy() {
    this.unsub.unsubscribe();
  }
}
